<template>
    <div class="mine-doctor">
        <div class="doctor-titbox" id="jz-card">
            <img src="../../assets/images/avadar.png" alt="">
            <p class="doctor-titbox-p">
                张三<em class="myself-box">(本人)</em>
            </p>
        </div>
        <div class="m-wrapper02">
            <ul class="m-doctor-list01">
                <li @click="toRecord" class="doctor-list-con01" v-for="doctor in doctorList" :key="doctor.doctorId">
                    <a>
                        <img src="../../assets/images/avatar-2.png" class="doctor-avatar01">
                        <div class="doctor-info-con01">
                            <div class="doctor-info-box01">
                                <span class="doctor-name01">{{doctor.doctorName}}</span>
                                <span class="doctor-tag">{{doctor.level}}</span>
                                <span class="state-cion b-green" :class="doctor.leftNum > 0 ? '' : 'bg-disabled'">
                                    {{doctor.leftNum > 0 ? '可预约' : '约满'}}
                                </span>
                            </div>
                            <div class="doctor-info-box02">
                                <span>{{doctor.hospitalName}}</span>
                                <span>{{doctor.departmentName}}</span>
                            </div>
                        </div>
                        <img src="../../assets/images/arrow-1.png" class="arrow01">
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
export default ({
    data() {
        return {

        }
    },
    computed: {
        ...mapState({
            doctorList: state => state.mineDoctor.doctorList
        })
    },
    mounted() {
        // 获取我的医生列表
        this.$store.dispatch('getDoctorList').then(() => {
            console.log('doctorList,', this.doctorList)
        })
    },
    methods: {
        toRecord() {
            // 跳转到该医生的预约界面
            this.$router.push('/appointment')
        }
    }
})
</script>
<style lang="scss" scoped>
    .doctor-titbox {
        position: relative;
        overflow: hidden;
        padding: 12px 12px;
        display: flex;
        align-items: center;
        background-color: #fff;
    }
    .doctor-titbox img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        display: block;
        margin-right: 10px;
    }
    .doctor-titbox-p {
        font-size: 18px;
        font-weight: 500;
    }
    .myself-box {
        font-style: normal;
        color: #f2a11c;
        font-size: 14px;
        font-weight: 400;
        margin-left: 6px;
    }
    .doctor-down {
        background: url(../../assets/images/arrow-2.png);
        background-size: 100% 100%;
        width: 12px;
        height: 8px;
        position: absolute;
        right: 12px;
    }
    .doctor-list-con01 img {
        border-radius: 100%;
    }

    .doctor-info-con01 {
        flex: 1;
        text-align: left;
        color: #333;
    }

    .doctor-list-con01 {
        background: #fff;
        margin-top: 12px;
        border-radius: 10px;
        display: flex;
    }
    .doctor-list-con01 a {
        width: 100%;
        padding: 16px 14px 16px 18px;
        align-items: center;
    }
    .doctor-avatar01 {
        width: 48px;
        height: 48px;
        margin-right: 12px;
    }
    .doctor-info-box01 {
        font-size: 0;
        font-weight: 500;
    }
    .doctor-name01 {
        font-size: 16px;
    }
    .doctor-tag {
        font-size: 12px;
        margin-left: 6px;
    }
    .doctor-info-box02 {
        font-size: 0;
        color: #999;
        margin-top: 8px;
    }
    .doctor-info-box02 span {
        font-size: 14px;
        margin-right: 6px;
        line-height: 16px;
    }
    .doctor-info-box02 span:first-child {
        margin-left: 0;
    }

    .doctor-list-con01 a {
        display: flex;
    }

    .arrow01 {
        width: 7px;
        height: 11px;
    }

    .m-wrapper02 {
        padding: 0 12px 12px;
    }

    .state-cion {
        color: #fff;
        font-size: 12px;
        border-radius: 20px;
        padding: 2px 8px;
        margin-left: 6px;
    }

    .b-gray {
        background-color: #999 !important;
    }
    .b-blue {
        background-color: #0086ff !important;
    }
    .b-green {
        background-color: #40d7a2 !important;

        &.bg-disabled {
            background: #ccc !important;
        }
    }


</style>
